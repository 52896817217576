import React from "react"
import Layout from "../components/layout"

const Blog = () => {
  return (
    <Layout>
      <h1>Blog Page</h1>
      <p>Blog posts will be displayed here! </p>
    </Layout>
  )
}

export default Blog
